/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/joy";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetListingByIdQuery,
  useUpdateListingMutation,
} from "services/osare";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardListingDocuments() {
  const { wizardId } = useParams();
  const formMethods = useForm();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const [updateListingData, updateListingResponse] = useUpdateListingMutation();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });
  const [getListingData, listingResponse] = useLazyGetListingByIdQuery();

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getListingData({
        id: wizardData.results[0].listingId,
      });
    }
  }, [wizardData]);

  const onSubmit = async (data) => {
    console.log(data);

    await updateListingData({
      id: wizardData.results[0].listingId,
      updateData: {
        termsAndConditions: data.termsAndConditions,
      },
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);
        navigate(`/dash/wizard/${wizardId}/accommodation/overview`);
      })
      .catch((error) => {
        console.error("Error updating event", error);
        setSaving(false);
        alert("Error updating listing, please try again.");
      });
  };

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      listingResponse.isSuccess &&
      !listingResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Box>
              <Box>
                <Typography level="h2">Documents</Typography>
                <Typography gutterBottom level="body-lg">
                  These are the documents that you can provide, which will be
                  shown to customers during the booking process.
                </Typography>
              </Box>

              <Box my={2}>
                <Typography level="title-lg">Terms &amp; Conditions</Typography>
                <Typography gutterBottom level="body-md">
                  Please provide your terms and conditions for this listing.
                  This will be shown to the user during the booking process and
                  they must agree to continue.
                </Typography>

                <DashEditor
                  name="termsAndConditions"
                  contentState={
                    listingResponse.data.results[0].termsAndConditions
                  }
                />
              </Box>
            </Box>

            <Box>
              <Button
                type="submit"
                startDecorator={
                  saving ? <CircularProgress size="sm" /> : <SaveAlt />
                }
              >
                {saving ? "Saving..." : "Save and Continue"}
              </Button>
            </Box>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
