/* eslint-disable no-unused-vars */

import { HelpOutline } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Card,
  FormLabel,
  Grid,
  Input,
  List,
  ListItem,
  ListItemContent,
  Switch,
  Typography,
} from "@mui/joy";
import _ from "lodash";
import { DateTime } from "luxon";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";

import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { useCreatePapertrailMutation } from "services/osare";

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className="control-group">
      <ButtonGroup size="sm">
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          Bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          Italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          Bullet list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          Ordered list
        </Button>
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          Undo
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          Redo
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default function PapertrailList(props) {
  const [contentState, setContentState] = useState({
    content: [],
    type: "doc",
  });
  const [sendToCustomer, setSendToCustomer] = useState(false);
  const [openCloseObject, setOpenCloseObject] = useState({});
  const [reversedArray, setReversedArray] = useState([]);
  const [createPapertrail, createPapertrailResult] =
    useCreatePapertrailMutation();
  const [title, setTitle] = useState("");
  const extensions = [StarterKit];

  useEffect(() => {
    if (!_.isEmpty(props.papertrailData)) {
      let reversed = [];
      reversed = [...props.papertrailData];
      reversed.reverse();

      setReversedArray([...reversed]);

      const openCloseObject = {};
      reversed.forEach((element) => {
        openCloseObject[element._id] = false;
      });

      setOpenCloseObject(openCloseObject);
    }
  }, [props.papertrailData]);

  useEffect(() => {
    if (createPapertrailResult.isSuccess) {
      console.log("createPapertrailResult", createPapertrailResult);
      enqueueSnackbar("Papertrail added successfully.", {
        variant: "success",
      });
    }
  }, [createPapertrailResult]);

  const addPapertrailItem = () => {
    if (title === "") {
      alert("Please specify a title for this papertrail item.");
      return;
    }

    createPapertrail({
      type: "orderNote",
      title,
      merchantFirebaseUserId: props.orderData.merchantFirebaseUserId,
      customerFirebaseUserId: props.orderData.customerFirebaseUserId,
      description: contentState,
      exposeToCustomer: sendToCustomer,
      metadata: {
        orderId: props.orderData._id,
        listingId: props.orderData.listingId,
      },
    });

    if (props.refetchPapertrail) {
      props.refetchPapertrail();
    }

    setTitle("");
    setContentState({
      blocks: [],
      entityMap: {},
    });
    setSendToCustomer(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Input
            size={"lg"}
            placeholder="Papertrail Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid xs={12}>
          <Card>
            <EditorProvider
              slotBefore={<MenuBar />}
              extensions={extensions}
              content={contentState}
              editorContainerProps={{
                style: {
                  background: "white",
                  paddingLeft: 10,
                  border: "1px solid #d6d6d6",
                  borderRadius: 8,
                },
              }}
              onContentError={(e) => {
                console.error(e);
              }}
              onUpdate={(editor) => {
                console.log(editor.editor.getJSON());
                setContentState(editor.editor.getJSON());
              }}
            ></EditorProvider>
            {/* <Editor
              initialContentState={{ blocks: [], entityMap: {} }}
              onContentStateChange={(cs) => {
                setContentState(cs);
              }}
              toolbar={{
                options: ["list"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
            /> */}
          </Card>
        </Grid>

        <Grid xs={12}>
          <FormLabel>
            <Switch
              checked={sendToCustomer}
              value={true}
              onChange={(e) => setSendToCustomer(e.target.checked)}
            />
            <Typography marginLeft={1}>
              Also send this update to the customer?
            </Typography>
          </FormLabel>
        </Grid>
        <Grid xs={12}>
          <Button variant="outlined" onClick={() => addPapertrailItem()}>
            Add papertrail item {sendToCustomer && "and send to customer"}
          </Button>
        </Grid>

        <Grid xs={12}>
          <Typography level="title-lg">Existing Papertrails</Typography>
        </Grid>

        {reversedArray.length === 0 && (
          <Grid xs={12}>
            <Typography startDecorator={<HelpOutline />}>
              No papertrail items currently exist for this order. You can add a
              new one above.
            </Typography>
          </Grid>
        )}

        {reversedArray.length > 0 && (
          <List>
            {reversedArray.map((p) => {
              return (
                <ListItem key={`list-${p._id}`}>
                  <ListItemContent>
                    <Typography level="title-sm">
                      {DateTime.fromMillis(p.createdAt).toLocaleString(
                        DateTime.DATETIME_MED_WITH_WEEKDAY
                      )}
                    </Typography>
                    <Typography level="title-md" noWrap>
                      {p.title}
                    </Typography>
                    <Typography level="body-sm">
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(p.description),
                        }}
                      /> */}
                    </Typography>
                  </ListItemContent>
                </ListItem>
              );
            })}
          </List>
        )}
      </Grid>
      <SnackbarProvider />
    </>
  );
}
