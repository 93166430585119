/* eslint-disable no-unused-vars */
import { Menu } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  ModalClose,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { getAuth } from "firebase/auth";
import {
  DocumentText,
  Home,
  Money,
  Personalcard,
  Setting,
} from "iconsax-react";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetAccountQuery, useGetConfigQuery } from "services/osare";
import DocumentViewerModal from "./Components/DocumentViewerModal/DocumentViewerModal";
import SuccessSnackbar from "./Components/SuccessSnackbar/SuccessSnackbar";
import WarningSnackbar from "./Components/WarningSnackbar/WarningSnackbar";
import DashHome from "./Screens/Home/Home";

export default function DashContainer() {
  const auth = getAuth();
  const navigate = useNavigate();
  const currentPath = useLocation();
  const {
    data: accountData,
    isLoading: isAccountDataLoading,
    isFetching: isAccountDataFetching,
    refetch: accountRefetch,
  } = useGetAccountQuery();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();

  const [documentViewerOpen, setDocumentViewerOpen] = React.useState(false);
  const [documentId, setDocumentId] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState({});
  const [settingsDrawerOpen, setSettingsDrawerOpen] = React.useState(false);

  useEffect(() => {
    if (
      accountData &&
      accountData.paymentGatewayData.requirements.eventually_due.length > 0
    ) {
      alert(
        "Sorry, onboarding is currently not complete yet. Please complete your onboarding process."
      );
      navigate("/onboard");
    }
  }, [accountData]);

  const logoutConfirmation = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      auth.signOut();
    }
  };

  const openDocumentViewer = (documentId) => {
    setDocumentId(documentId);
    setDocumentViewerOpen(true);
  };

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        accountRefetch();
      } else {
        navigate("/login");
      }
    });
  }, []);

  const SettingsDrawerComponent = () => {
    return (
      <Drawer
        size="md"
        variant="plain"
        open={settingsDrawerOpen}
        onClose={() => setSettingsDrawerOpen(false)}
        sx={{
          "--Drawer-transitionDuration": open ? "0.4s" : "0.2s",
          "--Drawer-transitionFunction": open
            ? "cubic-bezier(0.79,0.14,0.15,0.86)"
            : "cubic-bezier(0.77,0,0.18,1)",
        }}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <DialogTitle>
            {isAccountDataFetching
              ? "Loading..."
              : currentUser.displayName || "Settings"}
          </DialogTitle>
          <ModalClose />

          <DialogContent sx={{ gap: 2 }}>
            <Typography>{currentUser.email}</Typography>
            <Card>
              <Typography startDecorator={<Home />}>
                <Link
                  onClick={() => {
                    navigate("/dash");
                    setSettingsDrawerOpen(false);
                  }}
                >
                  Dashboard
                </Link>
              </Typography>
              <Typography startDecorator={<DocumentText />}>
                <Link
                  onClick={() => {
                    navigate("/dash/listings");
                    setSettingsDrawerOpen(false);
                  }}
                >
                  Listings
                </Link>
              </Typography>
              <Typography startDecorator={<Personalcard />}>
                <Link
                  onClick={() => {
                    navigate("/dash/staff");
                    setSettingsDrawerOpen(false);
                  }}
                >
                  Organisers &amp; Teachers
                </Link>
              </Typography>
              <Typography startDecorator={<Money />}>
                <Link
                  onClick={() => {
                    navigate("/dash/settings/payouts");
                    setSettingsDrawerOpen(false);
                  }}
                >
                  Payouts
                </Link>
              </Typography>
              <Typography startDecorator={<Setting />}>
                <Link
                  onClick={() => {
                    navigate("/dash/settings");
                    setSettingsDrawerOpen(false);
                  }}
                >
                  Settings
                </Link>
              </Typography>
            </Card>
          </DialogContent>

          <Divider sx={{ mt: "auto" }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            useFlexGap
            spacing={1}
          >
            <Button onClick={() => setSettingsDrawerOpen(false)}>Close</Button>
            <Button
              onClick={() => logoutConfirmation()}
              variant="plain"
              color="danger"
            >
              Logout
            </Button>
          </Stack>
        </Sheet>
      </Drawer>
    );
  };

  const LoadingUserComponent = () => {
    return (
      <Grid xs={12} textAlign={"center"} marginTop={10}>
        <CircularProgress />
      </Grid>
    );
  };

  const NavigationTabs = () => {
    return (
      <Stack direction={"row"} spacing={2}>
        <ButtonGroup
          disabled={false}
          orientation="horizontal"
          spacing={2}
          variant="plain"
        >
          <Button onClick={() => navigate("/dash")}>Home</Button>
          <Button onClick={() => navigate("/dash/listings")}>Listings</Button>
          <Button onClick={() => navigate("/dash/accommodation")}>
            Accommodation
          </Button>
          <Button onClick={() => navigate("events")}>Events</Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const DashContainerComponent = () => {
    return (
      <>
        <WarningSnackbar />
        <SuccessSnackbar />

        <Grid container>
          <Grid xs={"auto"}>
            <IconButton
              onClick={() => {
                setSettingsDrawerOpen(true);
                // accountRefetch();
              }}
            >
              <Menu />
            </IconButton>
          </Grid>
          <Grid xs={"auto"}>
            <Typography
              textAlign={"center"}
              level="h3"
              marginLeft={1}
              marginRight={2}
              onClick={() => navigate("/dash")}
              sx={{ cursor: "pointer" }}
            >
              {isConfigLoading ? "" : configData.name}
            </Typography>
          </Grid>
          {/* <Grid xs={"auto"}>
            <NavigationTabs />
          </Grid> */}
          <Grid xs={12}>
            {currentPath.pathname === "/dash" ? <DashHome /> : <Outlet />}
          </Grid>
          <Grid xs={12} marginTop={5} marginBottom={3}>
            <Divider>&copy; Osare.io Ltd</Divider>
            <DocumentViewerModal
              documentId={documentId}
              open={documentViewerOpen}
              setOpenFn={setDocumentViewerOpen}
              accountData={accountData?.paymentGatewayData}
              userData={currentUser}
            />
            <Box marginTop={2} textAlign={"center"}>
              <Typography
                level="body-sm"
                mr={2}
                display={"inline"}
                textAlign={"center"}
              >
                <Link onClick={() => openDocumentViewer("terms_of_service")}>
                  Terms of Service
                </Link>
              </Typography>
              <Typography
                level="body-sm"
                mr={2}
                display={"inline"}
                textAlign={"center"}
              >
                <Link onClick={() => openDocumentViewer("privacy_policy")}>
                  Privacy Policy
                </Link>
              </Typography>
              <Typography
                level="body-sm"
                mr={2}
                display={"inline"}
                textAlign={"center"}
              >
                <Link onClick={() => openDocumentViewer("agency_agreement")}>
                  Agency Agreement
                </Link>
              </Typography>
              <Typography
                level="body-sm"
                mr={2}
                display={"inline"}
                textAlign={"center"}
              >
                <Link
                  onClick={() => openDocumentViewer("booking_terms_of_service")}
                >
                  Booking Terms of Service
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Stack>
      {currentUser.email && !isAccountDataLoading ? (
        <>
          <DashContainerComponent />
          <SettingsDrawerComponent />
        </>
      ) : (
        <LoadingUserComponent />
      )}
    </Stack>
  );
}
