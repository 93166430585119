import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  useCreateStaffMutation,
  useGetMediaQuery,
  useGetStaffQuery,
} from "services/osare";

export default function DashStaff() {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const {
    data: staffData,
    isLoading: isStaffLoading,
    // isFetching: isStaffFetching,
    error: staffError,
    isError: isStaffError,
    // isSuccess: isStaffSuccess,
  } = useGetStaffQuery({}, { refetchOnMountOrArgChange: true });
  const [createStaff, createStaffResult] = useCreateStaffMutation();

  useEffect(() => {
    if (createStaffResult.isSuccess) {
      setCreationModalOpen(false);
      navigate(`/dash/staff/edit?id=${createStaffResult.data.id}`);
    }
  }, [createStaffResult]);

  const addStaff = () => {
    setCreationModalOpen(true);
    createStaff({});
  };

  const StaffCreationModal = ({ open = false }) => {
    return (
      <Modal open={open}>
        <ModalDialog>
          <Typography
            startDecorator={<CircularProgress sx={{ mr: 1 }} size="sm" />}
          >
            Creating...
          </Typography>
        </ModalDialog>
      </Modal>
    );
  };

  const StaffLoading = () => {
    return (
      <Box>
        <Typography level="h3" color={"neutral"} textAlign="center">
          Loading...
        </Typography>
      </Box>
    );
  };

  const StaffEmpty = () => {
    return (
      <Box>
        <Typography level="h4" color={"neutral"} textAlign="center">
          No Staff
        </Typography>
        <Typography color={"neutral"} textAlign="center">
          You can add a new member using the &quot;Add&quot; button above.
        </Typography>
      </Box>
    );
  };

  const STaffError = () => {
    return (
      <Box>
        <Typography level="h4" textAlign="center" color="danger">
          Error Loading Staff
        </Typography>
        <Typography mb={3} textAlign="center" color="danger">
          {staffError.message}
        </Typography>
        <hr />
      </Box>
    );
  };

  const StaffAvatar = (props) => {
    const {
      data: mediaData,
      isLoading: isMediaLoading,
      isFetching: isMediaFetching,
    } = useGetMediaQuery({
      collection: "staff",
      collectionId: props.staffId,
    });

    return isMediaLoading || isMediaFetching ? (
      <CircularProgress />
    ) : (
      <Avatar
        alt="Staff Avatar"
        src={mediaData?.results[0] ? mediaData.results[0].signedUrl : ""}
      />
    );
  };

  const StaffList = () => {
    return (
      <List
        aria-labelledby="ellipsis-list-demo"
        sx={{ "--ListItemDecorator-size": "56px" }}
      >
        {staffData.results.map((staff) => (
          <ListItem key={staff._id}>
            <ListItemButton
              onClick={() => navigate(`/dash/staff/edit?id=${staff._id}`)}
            >
              <ListItemDecorator>
                <StaffAvatar staffId={staff._id} />
              </ListItemDecorator>
              <ListItemContent>
                <Typography level="title-sm">
                  {staff.firstName ?? "(no first name)"}
                  &nbsp;
                  {staff.lastName ?? "(no last name)"}
                </Typography>
                <Typography level="body-sm" noWrap>
                  {staff.role ?? "No role specified"}
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  const StaffComponent = () => {
    return (
      <Box>
        <StaffCreationModal open={creationModalOpen} />
        <Typography
          marginTop={5}
          marginBottom={2}
          level="h3"
          textAlign={"center"}
        >
          Organisers &amp; Teachers
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ButtonGroup aria-label="outlined primary button group">
            <Button onClick={() => addStaff()}>Add</Button>
          </ButtonGroup>
        </Box>
        <Grid container>
          <Grid xs={0} sm={2}></Grid>
          <Grid xs={12} sm={8}>
            <Card sx={{ marginTop: 3 }}>
              {isStaffError ? <STaffError /> : null}
              {isStaffLoading ? <StaffLoading /> : null}
              {!staffData || staffData.results.length === 0 ? (
                <StaffEmpty />
              ) : (
                <StaffList />
              )}
            </Card>
          </Grid>
          <Grid xs={0} sm={2}></Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>{location.pathname === "/dash/staff" ? <StaffComponent /> : <Outlet />}</>
  );
}
