/* eslint-disable no-unused-vars */
import { Button, ButtonGroup, Card, Divider, Grid, Typography } from "@mui/joy";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useState } from "react";
import "./style.css";

import Placeholder from "@tiptap/extension-placeholder";
import { useFormContext } from "react-hook-form";

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className="control-group">
      <ButtonGroup size="sm">
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          Bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          Italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          Bullet list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          Ordered list
        </Button>
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          Undo
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          Redo
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default function ItineraryBuilder(props) {
  const { setValue } = useFormContext();
  const [itineraries, setItineraries] = useState(props.value);
  const [newItineraryDescription, setNewItineraryDescription] = useState({
    content: [],
    type: "doc",
  });

  const extensions = [StarterKit];

  const editItineraryItem = (idx, cs) => {
    const newArr = JSON.parse(JSON.stringify(itineraries));
    newArr[idx] = cs;
    setItineraries(newArr);
    setValue(props.name, newArr);
  };

  const addItineraryItem = () => {
    if (newItineraryDescription.content.length === 0) {
      alert("Please enter an itinerary description");
      return;
    }

    let newArr = JSON.parse(JSON.stringify(itineraries));
    newArr.push(newItineraryDescription);

    setItineraries(newArr);
    setValue(props.name, newArr);
    setNewItineraryDescription({ blocks: [], entityMap: {} });
  };

  const removeItineraryItem = (idx) => {
    const splicedArray = itineraries.toSpliced(idx, 1);
    setItineraries(splicedArray);

    if (props.onChange) {
      props.onChange(splicedArray);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {itineraries.map((i, idx) => {
          if (i.blocks) {
            i = {
              content: [{ type: "paragraph" }],
              type: "doc",
            };
          }

          return (
            <Grid key={`itinerarybuilder-${props.name}-${idx}`} xs={12}>
              <Card>
                <Typography level="body-sm">Itinerary Day {idx + 1}</Typography>
                <EditorProvider
                  slotBefore={<MenuBar />}
                  extensions={[
                    ...extensions,
                    Placeholder.configure({
                      placeholder: `Enter an itinerary description for Day ${
                        idx + 1
                      }`,
                    }),
                  ]}
                  content={i}
                  editorContainerProps={{
                    style: {
                      background: "white",
                      paddingLeft: 10,
                      border: "1px solid #d6d6d6",
                      borderRadius: 8,
                    },
                  }}
                  onContentError={(e) => {
                    console.error(e);
                  }}
                  onUpdate={(editor) => {
                    console.log(editor.editor.getJSON());
                    // setValue(props.name, editor.editor.getJSON());
                    editItineraryItem(idx, editor.editor.getJSON());
                  }}
                ></EditorProvider>
                <Grid container justifyContent="flex-end">
                  <Button
                    onClick={() => removeItineraryItem(idx)}
                    size="small"
                    color="danger"
                  >
                    Delete
                  </Button>
                </Grid>
              </Card>
              {itineraries.length === idx + 1 && (
                <Grid xs={12}>
                  <Divider sx={{ marginY: 2 }}>
                    Add another itinerary day
                  </Divider>

                  <Card>
                    <EditorProvider
                      slotBefore={<MenuBar />}
                      extensions={[
                        ...extensions,
                        Placeholder.configure({
                          placeholder: `Enter an itinerary description for the next day`,
                        }),
                      ]}
                      editorContainerProps={{
                        style: {
                          background: "white",
                          paddingLeft: 10,
                          border: "1px solid #d6d6d6",
                          borderRadius: 8,
                        },
                      }}
                      onContentError={(e) => {
                        console.error(e);
                      }}
                      onUpdate={(editor) => {
                        console.log(editor.editor.getJSON());
                        // setValue(props.name, editor.editor.getJSON());
                        setNewItineraryDescription(editor.editor.getJSON());
                      }}
                    ></EditorProvider>
                    {/* <Editor
                      initialContentState={newItineraryDescription}
                      placeholder={`A description of day ${
                        itineraries.length + 1
                      }`}
                      stripPastedStyles={true}
                      onContentStateChange={(cs) => {
                        setValue(props.name, cs);
                        console.log("content changing...", cs);
                        setNewItineraryDescription(cs);
                      }}
                      toolbar={{
                        options: ["list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        padding: "10px 30px",
                        border: "2px solid #d9d9d9",
                        borderRadius: "4px",
                        backgroundColor: "white",
                      }}
                    /> */}
                    <Grid container>
                      <Button
                        onClick={() => addItineraryItem()}
                        color="primary"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Card>
                </Grid>
              )}
            </Grid>
          );
        })}
        {!itineraries ||
          (itineraries.length === 0 && (
            <Grid xs={12}>
              <Divider>Start a new itinerary</Divider>

              <Card>
                <EditorProvider
                  slotBefore={<MenuBar />}
                  extensions={extensions}
                  // content={newItineraryDescription}
                  editorContainerProps={{
                    style: {
                      background: "white",
                      paddingLeft: 10,
                      border: "1px solid #d6d6d6",
                      borderRadius: 8,
                    },
                  }}
                  onContentError={(e) => {
                    console.error(e);
                  }}
                  onUpdate={(editor) => {
                    console.log(editor.editor.getJSON());
                    setValue(props.name, editor.editor.getJSON());
                    setNewItineraryDescription(editor.editor.getJSON());
                  }}
                ></EditorProvider>
                {/* <Editor
                  initialContentState={newItineraryDescription}
                  placeholder={`A description of day ${itineraries.length + 1}`}
                  stripPastedStyles={true}
                  onContentStateChange={(cs) => {
                    setValue(props.name, cs);
                    console.log("content changing...", cs);
                    setNewItineraryDescription(cs);
                  }}
                  toolbar={{
                    options: ["list"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                  }}
                /> */}
                <Grid container>
                  <Button onClick={() => addItineraryItem()} color="primary">
                    Add
                  </Button>
                </Grid>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
}
