/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/joy";
import isEventComponentEnabled from "helpers/isEventComponentEnabled";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import DietarySelector from "pages/Dash/Components/DietarySelector/DietarySelector";
import DrinksSelector from "pages/Dash/Components/DrinksSelector/DrinksSelector";
import GalleryManager from "pages/Dash/Components/GalleryManager/GalleryManager";
import MealsSelector from "pages/Dash/Components/MealsSelector/MealsSelector";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetEventByIdQuery,
  useUpdateEventMutation,
} from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardListingsFoodDrink() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { wizardId } = useParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const formMethods = useForm();
  const { t } = useTranslation();
  const [updateEventData, updateEventResponse] = useUpdateEventMutation();
  const [getEventData, eventResponse] = useLazyGetEventByIdQuery();
  const [saving, setSaving] = useState(false);
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });

  const onSubmit = async (data) => {
    console.log(data);

    /**
     * TODO: Figure out how to do the following validation with react-hook-form
     */
    if (data.overallFoodDescription.content.length === 0) {
      alert("Please fill in the food overview section");
      return;
    }

    setSaving(true);

    await updateEventData({
      id: wizardData.results[0].eventId,
      updateData: {
        overallFoodDescription: data.overallFoodDescription,
        includedMeals: data.includedMeals,
        dietarySupport: data.dietarySupport,
        includedDrinks: data.includedDrinks,
      },
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);
        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Saved!",
          })
        );
        navigate(`/dash/wizard/${wizardId}/listing/documents`);
      })
      .catch((error) => {
        console.error("Error updating event", error);
        setSaving(false);
        alert("Error updating event, please try again.");
      });
  };

  useEffect(() => {
    if (eventResponse.isSuccess && eventResponse.data) {
      console.log("Event data", eventResponse.data);
      formMethods.setValue(
        "overallFoodDescription",
        eventResponse.data.results[0].overallFoodDescription
      );
      formMethods.setValue(
        "includedMeals",
        eventResponse.data.results[0].includedMeals
      );
      formMethods.setValue(
        "dietarySupport",
        eventResponse.data.results[0].dietarySupport
      );
      formMethods.setValue(
        "includedDrinks",
        eventResponse.data.results[0].includedDrinks
      );
    }
  }, [eventResponse]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getEventData(wizardData.results[0].eventId);
    }
  }, [wizardData]);

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      eventResponse.isSuccess &&
      !eventResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            {/**
             * From Events - Food and Drink
             */}

            {isEventComponentEnabled(configData, "overallFoodDescription") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.overallFoodDescription.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t(
                    "dashboard.events.upsert.overallFoodDescription.description"
                  )}
                </Typography>

                <DashEditor
                  name="overallFoodDescription"
                  contentState={
                    eventResponse.data.results[0].overallFoodDescription
                  }
                />
              </Box>
            )}

            <Box marginY={2}>
              <Typography gutterBottom level="title-lg">
                {t("dashboard.accommodation.upsert.photos.title")}
              </Typography>

              <Typography gutterBottom level="body-md">
                Please upload high-quality photos of your food offerings to
                showcase the delicious and nutritious meals your retreat
                provides. These images will be used on your retreat&apos;s
                listing.
              </Typography>

              <GalleryManager
                collection={"food-drink"}
                collectionId={wizardData.results[0].eventId}
              />
            </Box>

            {isEventComponentEnabled(configData, "includedMeals") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.includedMeals.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.includedMeals.description")}
                </Typography>

                <MealsSelector
                  name="includedMeals"
                  value={eventResponse.data.results[0].includedMeals}
                />
              </Box>
            )}

            {isEventComponentEnabled(
              configData,
              "includedFoodDietaryTypes"
            ) && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.includedFoodDietaryTypes.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t(
                    "dashboard.events.upsert.includedFoodDietaryTypes.description"
                  )}
                </Typography>

                <DietarySelector
                  name="dietarySupport"
                  value={eventResponse.data.results[0].dietarySupport}
                />
              </Box>
            )}

            {isEventComponentEnabled(configData, "includedDrinks") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.includedDrinks.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.includedDrinks.description")}
                </Typography>

                <DrinksSelector
                  name="includedDrinks"
                  value={eventResponse.data.results[0].includedDrinks}
                />
              </Box>
            )}

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Save and Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
