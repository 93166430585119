/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Link,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from "@mui/joy";
import {
  Building3,
  Document,
  Map1,
  Milk,
  Moneys,
  Note,
  Setting3,
  Star1,
} from "iconsax-react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetConfigQuery, useGetWizardsQuery } from "services/osare";

export default function Wizard() {
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { wizardId } = useParams();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });

  return (
    <Grid m={1} container spacing={2}>
      <>
        <Grid xs={2}>
          <Stepper sx={{ position: "fixed" }} orientation="vertical">
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={
                <StepIndicator variant="solid" color="primary">
                  1
                </StepIndicator>
              }
            >
              <Typography>Retreat Program</Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Star1 />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/listing/basics`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/listing/basics`)
                  }
                >
                  The Basics
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Note />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/listing/itinerary`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/listing/itinerary`)
                  }
                >
                  Itinerary
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Milk />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/listing/food-and-drink`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/listing/food-and-drink`)
                  }
                >
                  Food & Drink
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Document />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/listing/documents`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/listing/documents`)
                  }
                >
                  Documents
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={
                <StepIndicator variant="solid" color="primary">
                  2
                </StepIndicator>
              }
            >
              <Typography>Accommodation</Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Star1 />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/accommodation/overview`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/accommodation/overview`)
                  }
                >
                  Overview
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Building3 />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/accommodation/rooms`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/accommodation/rooms`)
                  }
                >
                  Rooms
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Map1 />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/accommodation/location`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/accommodation/location`)
                  }
                >
                  Location &amp; Travel
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={
                <StepIndicator variant="solid" color="primary">
                  3
                </StepIndicator>
              }
            >
              <Typography>Availability &amp; Pricing</Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Setting3 />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/pricing/booking-settings`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(
                      `/dash/wizard/${wizardId}/pricing/booking-settings`
                    )
                  }
                >
                  Booking Settings
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Moneys />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/pricing/dates-prices`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/pricing/dates-prices`)
                  }
                >
                  Availability &amp; Pricing
                </Link>
              </Typography>
            </Step>
            <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Document />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/pricing/options`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/pricing/options`)
                  }
                >
                  Payment & Cancelations
                </Link>
              </Typography>
            </Step>
            {/* <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Moneys />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/pricing/estimated-earnings`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(
                      `/dash/wizard/${wizardId}/pricing/estimated-earnings`
                    )
                  }
                >
                  Estimated Earnings
                </Link>
              </Typography>
            </Step> */}
            {/* <Step
              sx={{
                "&::after": {
                  background: "black",
                },
              }}
              indicator={<Document />}
            >
              <Typography
                fontWeight={
                  location.pathname ===
                  `/dash/wizard/${wizardId}/pricing/policies`
                    ? "bold"
                    : "normal"
                }
                level="body-sm"
              >
                <Link
                  onClick={() =>
                    navigate(`/dash/wizard/${wizardId}/pricing/policies`)
                  }
                >
                  Policies
                </Link>
              </Typography>
            </Step> */}
          </Stepper>
        </Grid>
        <Grid xs={10}>
          <>
            <Grid container>
              <Grid xs={0} md={2}></Grid>
              <Grid xs={12} md={8}>
                {location.pathname === `/dash/wizard/${wizardId}` ? (
                  <>
                    <Typography level="title-lg">
                      Build Your Retreat Program
                    </Typography>
                    <Typography level="title-md">
                      Provide all important information about the program your
                      retreat offers. This thorough description helps guests
                      understand the full scope of what your retreat offers,
                      aiding them in deciding if it aligns with their interests
                      and needs.
                    </Typography>

                    <Box m={2} />

                    <Button
                      onClick={() =>
                        navigate(`/dash/wizard/${wizardId}/listing/basics`)
                      }
                    >
                      Continue to Basics
                    </Button>

                    <Typography mt={1}>
                      {isWizardDataLoading ? (
                        "Loading..."
                      ) : (
                        <Link
                          target="_blank"
                          href={`${configData.sites.bookings.url}/listing?listingId=${wizardData.results[0].listingId}`}
                        >
                          or, view your listing
                        </Link>
                      )}
                    </Typography>
                  </>
                ) : (
                  <Outlet />
                )}
              </Grid>
              <Grid xs={0} md={2}></Grid>
            </Grid>
          </>
        </Grid>
      </>
    </Grid>
  );
}
