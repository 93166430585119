/* eslint-disable no-unused-vars */
import {
  Check,
  Receipt,
  Refresh,
  SubdirectoryArrowRight,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/joy";
import { DateTime } from "luxon";
import { useGetPayoutsQuery } from "services/osare";

export default function Payouts() {
  const {
    data: payoutsData,
    isLoading,
    isFetching,
    isError,
    refetch: payoutsRefetch,
  } = useGetPayoutsQuery();

  const ScheduledPaymentItem = ({ paymentItem }) => {
    console.log("paymentItem", paymentItem);
    if (!paymentItem) return null;
    return (
      <Card>
        <Typography level="title-lg">
          {paymentItem.purpose === "platformAndDepositFee"
            ? "Deposit"
            : "Remaining package cost"}
        </Typography>
        <Card>
          <Typography>
            Charge for GBP {paymentItem.chargeAmount} on{" "}
            {DateTime.fromMillis(paymentItem.onOrAfterTimestamp).toLocaleString(
              DateTime.DATE_FULL
            )}
          </Typography>
          {paymentItem.status && (
            <Typography
              component={"div"}
              startDecorator={<SubdirectoryArrowRight />}
            >
              Customer charge:{" "}
              <Chip
                color={
                  paymentItem.status === "succeeded" ? "success" : "neutral"
                }
              >
                {paymentItem.status.toUpperCase()}
              </Chip>
            </Typography>
          )}
        </Card>
        {paymentItem.resolvedPayoutData && (
          <Card>
            <Typography level="body-lg">Payout status</Typography>
            <Typography startDecorator={<SubdirectoryArrowRight />}>
              Status:{" "}
              <Chip
                color={
                  paymentItem.resolvedPayoutData.status === "paid"
                    ? "success"
                    : "neutral"
                }
              >
                {paymentItem.resolvedPayoutData.status.toUpperCase()}
              </Chip>
            </Typography>
            <Typography pr={3} startDecorator={<Check />}>
              Started on:{" "}
              {DateTime.fromSeconds(
                paymentItem.resolvedPayoutData.created
              ).toLocaleString(DateTime.DATE_FULL)}{" "}
              and expected to arrive on:{" "}
              {DateTime.fromSeconds(
                paymentItem.resolvedPayoutData.arrival_date
              ).toLocaleString(DateTime.DATE_FULL)}
            </Typography>
          </Card>
        )}

        {paymentItem.lastPayoutErrorCode && (
          <Card color="warning" variant="soft">
            <Typography gutterBottom color="warning">
              The last attempt to pay out failed{" "}
              {DateTime.fromMillis(paymentItem.updatedAt).toRelative()} with
              error code: {paymentItem.lastPayoutErrorCode} and will try again
              on the hour
            </Typography>
          </Card>
        )}
      </Card>
    );
  };

  const OrderItem = ({ orderData }) => {
    return (
      <Box mb={2}>
        <Card>
          <Typography startDecorator={<Receipt />} level="title-lg">
            <Link href={`/dash/orders/single?id=${orderData[0]}`}>
              Order #{orderData[0]}
            </Link>
          </Typography>
          <ScheduledPaymentItem
            paymentItem={orderData[1].find(
              (sp) => sp.purpose === "platformAndDepositFee"
            )}
          />
          <ScheduledPaymentItem
            paymentItem={orderData[1].find(
              (sp) => sp.purpose === "remainingPackageFee"
            )}
          />
        </Card>
      </Box>
    );
  };

  return (
    <Grid container mt={3}>
      <Grid md={3}></Grid>
      <Grid xs={12} md={6}>
        <Typography
          level="h2"
          gutterBottom
          endDecorator={
            <IconButton onClick={() => payoutsRefetch()}>
              <Refresh />
            </IconButton>
          }
        >
          Payouts
        </Typography>

        {(isLoading || isFetching) && <LinearProgress />}

        {!isLoading &&
          !isError &&
          payoutsData &&
          Object.entries(payoutsData).map((orderData, idx) => (
            <OrderItem key={`order-id-${idx}`} orderData={orderData} />
          ))}
      </Grid>
      <Grid md={3}></Grid>
    </Grid>
  );
}
